import React from 'react';

import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import IpPhoneImage4 from '../../../assets/images/_2.png';
import IpPhoneImage2 from '../../../assets/images/_1.png';
import IpPhoneImage3 from '../../../assets/images/softphone-Photoroom.png';
import Phone from '../../../assets/images/phone-Photoroom.png';

import './IpPhone.css';

const IpPhone = () => {
  return (
    <>
      <Container fluid className="">
        <Row>
          <Col>
            <div className="ipphone-service-title mb-4">
              <h1 className="mb-4">Telefonia Fixa via Internet</h1>
              <p>
                Oferecemos serviços de telefonia fixa através da internet, proporcionando flexibilidade e economia.
                Se você possue uma conexão de internet você pode contratar nossos serviços.</p>                
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="ipphone-service">
        <Row>
          <Col md={7}>
            <div className='text-phone-title-list mb-4'>
              <h1 className="mb-4">Portabilidade do seu número</h1>
              <div className='list'>
                <ul>
                  <li><i class="fa fa-check"> </i> Portabilidade do Número Fixo</li>
                  <li><i class="fa fa-check"> </i> Novos Números Disponíveis</li>
                  <li><i class="fa fa-check"> </i> Comodidade, nós realizaremos o pedido da portabilidade </li>
                  <li><i class="fa fa-check"> </i> Nosso processo é simples e rápido</li>
                </ul>
              </div>
              <p>
                A Gigabyte cuidará dos pedidos de portabilidade do seu número fixo.
                As solicitações e o acompanhamento desse processo serão feitos pela nossa equipe.
              </p>

              <h4>
                Obs: O cliente não deve solicitar o cancelamento da linha telefonica, isso resultará na perda do número impossibilitando a portabilidade.
              </h4>

            </div>
          </Col>
          <Col md={5}>
            <img src={IpPhoneImage2} alt='ipphone' />
          </Col>
        </Row>
      </Container>
      <Container fluid className="ipphone-service2">
        <Row>
          <Col md={5}>
            <img src={Phone} alt='Phone' />
          </Col>
          <Col md={7}>
            <div className='text-phone-title-list mb-4'>
              <h1 className="mb-4">Aproveite Seus Equipamentos Antigos</h1>
              <div className='list'>
                <ul>
                  <li><i class="fa fa-check"> </i> Compatível com telefones antigos</li>
                  <li><i class="fa fa-check"> </i> Compatível com a maioria dos PABXs</li>
                  <li><i class="fa fa-check"> </i> Análise de compatibilidade gratuita</li>
                </ul>
              </div>
              <p>

              </p>

              <h5>
                Alguns aparelhos antigos podem não ser compatíveis com nossas tecnologias Sigame e Bina.
              </h5>

            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="ipphone-service">
        <Row>
          <Col md={7}>
            <div className='text-phone-title-list mb-4'>
              <h1 className="mb-4">Oferecemos Opções Modernas de IpPhone</h1>
              <div className='list'>
                <ul>
                  <li><i class="fa fa-check"> </i> Facilidade de uso e instalação</li>
                  <li><i class="fa fa-check"> </i> Facilidade de Integração</li>
                  <li><i class="fa fa-check"> </i> Qualidade de Áudio</li>
                  <li><i class="fa fa-check"> </i> Recursos Avançados</li>
                  <li><i class="fa fa-check"> </i> Escalabilidade</li>
                </ul>
              </div>
              <p>
                Facilidade de escalabilidade, permitindo adicionar ou remover linhas e usuários sem a necessidade de infraestrutura adicional, ideal para empresas em crescimento.
              </p>

              <h3>
                ATENÇÃO! Aluguel de aparelhos por apenas: R$ 30,00/mês
              </h3>
              <span className='consulta'>Observação: Sujeito a consulta.</span>
            </div>
          </Col>
          <Col md={5}>
            <img src={IpPhoneImage4} alt='ipphone' />
          </Col>
        </Row>
        {/* <Row>
          <Col md={12}>
            Se preferir atualizar seus equipamentos, oferecemos opções de venda ou aluguel de IpPhones, PABX virtual e muito mais. Modernize sua infraestrutura com as melhores tecnologias do mercado.
            <br />
            <h3>
              ATENÇÃO! Aluguel de aparelhos por apenas: R$ 30,00/mês
            </h3>
            <span className='consulta'>Observação: Sujeito a consulta.</span>
          </Col>
        </Row> */}
      </Container>
      <Container fluid className="ipphone-service2">
        <Row>
          <Col md={5}>
            <img src={IpPhoneImage3} alt='ipphone-infra3' className='ipphone-infra3' />
          </Col>
          <Col md={7}>
            <div className='text-phone-title-list mb-4'>
              <h1 className="mb-4">Por Que Escolher a Gigabyte Telecom?</h1>
              <div className='list'>
                <ul>
                  <li><i class="fa fa-check"> </i> Facilidade de uso e instalação</li>
                  <li><i class="fa fa-check"> </i> Economia em chamadas</li>
                  <li><i class="fa fa-check"> </i> Portabilidade de números</li>
                  <li><i class="fa fa-check"> </i> Suporte para equipamentos antigos</li>
                  <li><i class="fa fa-check"> </i> Opções de equipamentos modernos</li>
                  <li><i class="fa fa-check"> </i> PABX Virtual</li>
                </ul>
              </div>

              <h6>
                Menor complexidade na instalação e manutenção, já que utilizam a infraestrutura de rede existente, reduzindo a necessidade de cabos telefônicos separados.
              </h6>

            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default IpPhone;