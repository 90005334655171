import React, { useState, useEffect } from "react";
import Logo from '../../assets/images/logoGiga2.png';
import Google from '../../assets/images/googleplay.png';
import AppleStore from '../../assets/images/AppStore.png';
import { useMediaQuery } from 'react-responsive';
import Privacidade from "../../pages/Politicas/Privacidade";
import CookiesPage from "../../pages/Politicas/CookiesPage";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import 'font-awesome/css/font-awesome.min.css';
import './styles.css';


const Footer = () => {

    // Estados para controlar os modais
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const [showCookiesModal, setShowCookiesModal] = useState(false);

    // Funções para abrir e fechar os modais
    const handleOpenPrivacyModal = () => setShowPrivacyModal(true);
    const handleClosePrivacyModal = () => setShowPrivacyModal(false);

    const handleOpenCookiesModal = () => setShowCookiesModal(true);
    const handleCloseCookiesModal = () => setShowCookiesModal(false);

    const message = "Internet, TV por Assinatura, Telefonia Fixa, Internet Rural";

    const [whatsAppLink, setWhatsAppLink] = useState("");

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const baseURL = "https://wa.me/556222000000";
        const messageParam = encodeURIComponent(`Olá, entre para falar sobre: ${message}`);

        if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent)) {
            setWhatsAppLink(`whatsapp://send?phone=556222000000&text=${messageParam}`);
        } else {
            setWhatsAppLink(`${baseURL}?text=${messageParam}`);
        }
    }, [message]);

    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <>
            <div className="container-fluid">
                <div className="row my-row-footer">
                    <div className="col-sm-12 col-md-5 my-col-footer">
                        <h1>Gigabyte Telecom</h1>
                        <p>
                            A GigaByte Telecom e uma operadora de telecomunicações
                            que oferece soluções em Internet, TV a cabo e Telefonia Fixa.
                        </p>
                        <p>
                            CNPJ: 08.664.106/0001-00
                        </p>
                        <div className="store">
                            <a href="https://play.google.com/store/apps/details?id=br.com.badbit.gigabytetelecom&hl=pt_BR" target="_blank" rel="noopener noreferrer">
                                <img src={Google} alt="" />
                            </a>
                            <a href="https://apps.apple.com/br/app/gigabyte-telecom-cliente/id1620548702" target="_blank" rel="noopener noreferrer">
                                <img src={AppleStore} alt="" />
                            </a>
                        </div>
                    </div>
                    {isMobile ? <div></div> : (
                        <div className="col-sm-12 col-md-4 footer-links">
                            <h3>Mapa de Navegação</h3>
                            <ul>
                                <li><a href="/">Página Inicial</a> </li>
                                <li><a href="/internet">Planos Internet</a></li>
                                <li><a href="/internet-rural">Planos Internet Rural</a></li>
                                <li><a href="tvs">TV por Assinatura</a></li>
                                <li><a href="/telefone">Telefonia Fixa</a></li>
                                <li><a href="https://gigabytemultimidia.com.br/" target="_blank">Giga Multimídia</a></li>
                                <li><a href="#">Quem Somos</a></li>
                                <li><a href="#">Política de Privacidade</a></li>
                                <li><a href="#">Política de Cookies</a></li>
                            </ul>
                            <br />
                        </div>
                    )}
                    <div className="col-sm-12 col-md-3 contact-social">
                        <div className="footer-contact">
                            <h3>Entre em Contato</h3>
                            <p>(62) 2200-0000 | 0800 062 2200</p>
                            <p>faleconosco@gigabytetelecom.com.br</p>
                        </div>
                        <h3>Siga-nos:</h3>
                        <div className="footer-contact-social">
                            <h2 className="icons">
                                <a href={whatsAppLink} target="_blank"><i className="fa fa-whatsapp" aria-hidden="true"></i></a>
                                <a target="_blank" href="https://www.facebook.com/gigabytetelecom"><i className="fa fa-facebook-official" aria-hidden="true"> </i></a>
                                <a target="_blank" href="https://www.instagram.com/gigabytetelecom/"><i className="fa fa-instagram" aria-hidden="true"> </i></a>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="footer">
                {isMobile ? (
                    <div className='logo-mob'>
                        <img src={Logo} alt='logo2' />
                    </div>

                ) : (
                    <div className='footer'>
                        <div className='copyright'>© 2007 - 2024 - Gigabyte Telecom</div>
                        <div className='logo'><img src={Logo} alt='logo2' /></div>
                        <div className='private'>
                            <span onClick={handleOpenPrivacyModal}>
                                Políticas De Privacidade
                            </span>
                            {' - '}
                            <span onClick={handleOpenCookiesModal}>
                                Políticas De Cookies
                            </span>
                        </div>
                    </div>
                )}
            </div>

            {/* Modal para Políticas de Privacidade */}
            <Modal show={showPrivacyModal} onHide={handleClosePrivacyModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Política de Privacidade</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Privacidade />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePrivacyModal}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal para Políticas de Cookies */}
            <Modal show={showCookiesModal} onHide={handleCloseCookiesModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Política de Cookies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CookiesPage />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCookiesModal}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Footer;
