import React from 'react';
import ContactForm from '../../components/ContactForm/ContactForm';

import './ContactPage.css';
import WhatsApp from '../../components/WhatsApp/WhatsApp';

const ContactPage = () => {
  return (
    <>
      <ContactForm />
    </>
  );
};

export default ContactPage;
