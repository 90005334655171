import React, {useState} from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import SeparatorBar from '../../SeparatorBar/SeparatorBar';
import ContactForm from '../../../components/ContactForm/ContactForm';

import './RuralInternetPlan.css';


const RuralInternetPlan = () => {

  const title='Formulário de contato'

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [showChannels, setShowChannels] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  
  const handleShowChannels = (title) => {
    setSelectedPlan(title);
    setShowChannels(true);
  };
  
  const handleClose = () => {
    setShowMoreInfo(false);
    setShowChannels(false);
  };

  return (
    <>
      <div className='rural-plan'>
        <Container className="rural-internet-plan my-5">
          <br />
          <h1 className="text-center-rural mb-4">Plano Internet Rural</h1>
          <Row className="justify-content-center">
            <Col md={12}>
              <Card className="plan-card text-center">
                <Card.Body>
                  <Card.Text>
                    <h2>Aproveite nossa promoção!</h2>
                    <SeparatorBar />
                  </Card.Text>
                  <Card.Text className="lead">
                    <strong>Valor promocional R$ 400,00*</strong> para pagamento à vista!<br />
                    Consulte valores fora da promoção.
                  </Card.Text>
                  <Card.Text className="lead">
                    <strong>A partir de R$ 99,99*</strong> mensal.<br />
                    Promoção válida para pagamentos até a data do vencimento.
                  </Card.Text>
                  <Card.Text className="lead">
                    Consulte disponibilidade através da localização.<br />
                    Você poderá solicitar sua internet através das regiões disponíveis: Jaraguá, Ceres, Rialma, Uruana, Rubiataba e Uruaçu.
                  </Card.Text>
                  <Card.Text className="lead">
                    <strong>Incluso no kit:</strong>
                    <ul>
                      <li>Uma antena</li>
                      <li>Chip incluso na antena</li>
                      <li>Um Roteador com sinal wi-fi acoplado</li>
                    </ul>
                    <em>Obs: Não é necessário comprar nenhum equipamento, todo o kit incluso é em comodato.</em>
                  </Card.Text>
                  <Button variant="primary" className="btn-signup" onClick={() => handleShowChannels(title)}>CONTRATE AGORA</Button>
                  <p className="text-muted mt-3">Clique em assinar para verificar a disponibilidade para sua localidade.</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <br />

        {/* Modal para Ver Canais */}
        <Modal show={showChannels} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Formulário de Contato</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ContactForm />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default RuralInternetPlan;
