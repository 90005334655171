import { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage';

const AppRedirect = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Verifica se o dispositivo é Android
    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.gigabytemobile&hl=pt_BR&pli=1';
    }
    // Verifica se o dispositivo é iOS
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/br/app/gigabyte-play/id6443457059?l=pt';
    }
    // Exibe o Toast caso o dispositivo não seja Android nem iOS
    else {
      toast.info('O aplicativo está disponível apenas para Android e iOS.', {
        position: "top-center",
        autoClose: 4000, // 5 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      // Redireciona para a página inicial após o tempo de exibição do toast
      setTimeout(() => {
        window.location.href = '/';
      }, 4000); // 5 segundos (mesmo tempo que o toast é exibido)
    }
  }, []);
  

  return (
    <>
      <ToastContainer />
      <NotFoundPage />
    </>
  );
};

export default AppRedirect;
