import { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppRedirect = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Verifica se o dispositivo é Android
    if (/android/i.test(userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=br.com.badbit.gigabytetelecom&hl=pt_BR', '_blank');
      window.location.href = '/';
    }
    // Verifica se o dispositivo é iOS
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.open('https://apps.apple.com/br/app/gigabyte-telecom-cliente/id1620548702', '_blank');
      window.location.href = '/';
    }
    // Exibe o Toast e redireciona para a página inicial se o dispositivo não for Android nem iOS
    else {
      toast.info('O aplicativo está disponível apenas para Android e iOS.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setTimeout(() => {
        window.location.href = '/';
      }, 3000); // 5 segundos para exibir o Toast
    }
  }, []);

  return <ToastContainer />;
};

export default AppRedirect;
