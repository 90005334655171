import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import tvIcon from '../../../assets/images/television-icon.png';

import './CardTV.css';

const CardTV = () => {
  const [plans, setPlans] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  // Função para buscar planos de TV da API
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/tvPlans`);
        setPlans(response.data); // Define os planos recebidos pela API
      } catch (error) {
        console.error("Erro ao buscar os planos de TV:", error);
      }
    };
    fetchPlans();
  }, []);

  const handleShow = (plan) => {
    setSelectedPlan(plan);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedPlan(null);
  };

  return (
    <Container className="tv-plans my-5">
      <h1 className="text-center-title mb-4">Planos de TV</h1>
      <Row>
        {plans.map(plan => (
          <Col key={plan.id} md={6} lg={3}>
            <Card className="plan-card text-center">
              <Card.Img variant="top" src={`${process.env.REACT_APP_API_IMG}/assets/tvPlans/${plan.image}`} alt={plan.name} className="plan-image" />
              <Card.Body>
                <Card.Title>
                  <div className='img-tv'><img src={tvIcon} alt='icon' /></div> 
                  {plan.name}
                </Card.Title>
                <Card.Text>{plan.channels}</Card.Text>
                {plan.extraChannels && <Card.Text className='mb-2'>{plan.extraChannels}</Card.Text>}
                <Button variant="primary" onClick={() => handleShow(plan)}>Mais informações</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {selectedPlan && (
        <Modal show={show} onHide={handleClose} size="lg" dialogClassName="custom-modal-dark">
          <Modal.Header closeButton>
            <Modal.Title>
              <div className='icon-tv'><img src={tvIcon} alt='icon' /> {selectedPlan.name}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='custom-modal-dark-body'>
              <p>{selectedPlan.content}</p>
              <img src={`${process.env.REACT_APP_API_IMG}/assets/tvPlans/${selectedPlan.image}`} alt={selectedPlan.name} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="plan-tv-footer">
              <p className="mb-0">
                Baixe nosso aplicativo Gigabyte Play e comece a aproveitar, mesmo que você não seja assinante da nossa internet.
              </p>
              <Button variant="secondary" onClick={handleClose}>
                Fechar
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default CardTV;
